import React, { useEffect, useState } from 'react';
import { File, MarkdownRemarkConnection } from '@model/generated/index';
import { graphql, PageProps } from 'gatsby';
import { Layout } from '@components/global/Layout';
import { navigationItemsProjects } from '@model/projekte/navigationItemsProjects';
import Hero from '../../../resources/images/projekte/der_gelbe/hero.svg';
import Hand from '../../../resources/images/projekte/der_gelbe/hand.svg';
import { Meta } from '@components/global/Meta';

type DataProps = {
  postcards: MarkdownRemarkConnection;
};

type Props = PageProps<DataProps>;

export const query = graphql`
  query {
    postcards:  allMarkdownRemark(
      sort: {fields: fileAbsolutePath, order: DESC}
      filter: {fileAbsolutePath: {regex: "/(markdown)/postkarte/.*\\.md$/"}, frontmatter: {is_displayed: {eq: true}}}
    ) {
      edges {
        node {
          frontmatter {
            published_date
            wish_luedenscheid
            title
            is_displayed
          }
        }
      }
    }
  }
`;

const Page: React.FC<Props> = () => {
  const [likeLuedenscheid, setStateLikeLuedenscheid] = useState('');
  const [wishLuedenscheid, setStateWishLuedenscheid] = useState('');

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.GATSBY_RECAPTCHA_PUBLIC_SITE_KEY, {
          action: 'submit',
        })
        .then((token) => {
          fetch('/.netlify/functions/create-postcard', {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
              like_luedenscheid: likeLuedenscheid,
              wish_luedenscheid: wishLuedenscheid,
              token: token,
            }),
          })
            .then((value: Response) => {
              setStateLikeLuedenscheid('');
              setStateWishLuedenscheid('');
              if (value.status != 200) {
                throw Error(`Status Code - ${value.status}`);
              }
            })
            .catch((error) => console.log(error));
        });
    });
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_PUBLIC_SITE_KEY}`;
    document.body.appendChild(script);
  }, []);

  return (
    <Layout
      title="Projekte"
      navigationItems={navigationItemsProjects}
      backgroundColor="bg-yellow"
    >
      <div className="flex">
        <div className="flex-auto">
          <div className="flex">
            <div className="flex-auto items-center ml-16 mt-16">
              <Hero />
            </div>
            <h1 style={{ writingMode: 'vertical-rl' }}>Der Gelbe</h1>
          </div>
          <form
            className="flex flex-col lg:flex-row place-self-end"
            name="create-postcard"
            onSubmit={onSubmit}
            method="PUT"
          >
            <div className="flex-1 p-24 lg:p-32 m-16 bg-white lg:min-h-400 xl:min-h-592">
              <div>
                <div className="uppercase bg-yellow py-4 px-8 text-18 lg:text-24 font-AutogateSans">
                  Darum bin ich gerne in Lüdenscheid:
                </div>
                <textarea
                  className="py-8 text-16 lg:text-18 w-full min-h-80 lg:min-h-160"
                  name="like_luedenscheid"
                  onChange={(event) =>
                    setStateLikeLuedenscheid(event.target.value)
                  }
                  value={likeLuedenscheid}
                />
              </div>
              <div className="uppercase bg-yellow py-4 px-8 mt-32 text-18 lg:text-24 font-AutogateSans">
                Das wünsch' ich Lüdenscheid für die Zukunft:
              </div>
              <textarea
                className="py-8 text-16 lg:text-18 w-full min-h-80"
                name="wish_luedenscheid"
                onChange={(event) =>
                  setStateWishLuedenscheid(event.target.value)
                }
                value={wishLuedenscheid}
              />
            </div>
            <button
              className="hover:opacity-80 self-end lg:place-self-center pb-32 overflow-hidden"
              type="submit"
            >
              <div className="opacity-inherit font-AutogateSans text-18 lg:text-24">
                Einwerfen
              </div>
              <div className="w-160 xs:w-224 sm:w-320 -mr-16">
                <Hand />
              </div>
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export const Head = () => {
  return <Meta title="Der Gelbe" />;
};

export default Page;
